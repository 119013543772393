<template>
  <zg-section id="3rMpLl3KJrmaIDOZwqnjRl">
    <div class="container">
      <div
        :class="['zg-lead', {
          'zg-lead--with-background-element': backgroundElement,
          'zg-lead--email-sent': emailSent
        }]"
      >
        <template v-if="!fullFlip || !emailSent">
          <zg-illustration
            v-if="illustration"
            illustration="co-applicant"
          />

          <p class="title">
            {{ title }}
          </p>

          <p
            v-if="text"
            class="text"
          >
            {{ text }}
          </p>
        </template>

        <template v-if="!emailSent">
          <zg-input
            v-model="email"
            class="input"
            name="leadEmail"
            type="email"
            full-width
            :label="input.label"
            :error="input.error"
            :valid="valid"
            :invalid="invalid"
            :email-warning-language="input.emailWarningLanguage"
            @focus="onFocus"
            @input="onInput"
            @blur="onBlur"
          />

          <zg-button
            id="button"
            :class="{ sending: emailSending }"
            full-width
            @click="sendLead"
          >
            {{ buttonText }}
          </zg-button>

          <p
            class="disclaimer"
            v-html="disclaimer"
          />
        </template>

        <template v-else>
          <zg-icon
            class="icon"
            icon="check-circle"
            size="large"
            color="accent-300"
          />

          <p
            v-if="titleCompleted"
            class="title-completed"
          >
            {{ titleCompleted }}
          </p>

          <p class="text-completed">
            {{ textCompleted }}
          </p>
        </template>
      </div>
    </div>
  </zg-section>
</template>

<script setup lang="ts">
  import { useZmartaAnalytics } from '@ocp-zmarta/content-sdk'
  import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
  import { computed, defineAsyncComponent, type PropType, ref } from 'vue'
  import { request } from '../../../utils/request'

  const ctx = import.meta.env.SSR
    ? useSSRContext()
    : ({
      brand: getServerState('brand'),
      market: getServerState('market'),
      locale: getServerState('locale')
    }) as unknown as NonNullable<ReturnType<typeof useSSRContext>>

  const ZgButton = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgButton))
  const ZgIcon = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgIcon))
  const ZgIllustration = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgIllustration))
  const ZgInput = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgInput))
  const ZgSection = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgSection))

  defineProps({
    backgroundElement: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    illustration: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    title: {
      type: String as PropType<string>,
      required: true
    },
    text: {
      type: String as PropType<string>,
      default: ''
    },
    input: {
      type: Object as PropType<{ label: string, error: string, emailWarningLanguage: string }>,
      required: true
    },
    buttonText: {
      type: String as PropType<string>,
      required: true
    },
    titleCompleted: {
      type: String as PropType<string>,
      default: ''
    },
    textCompleted: {
      type: String as PropType<string>,
      required: true
    },
    fullFlip: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    disclaimer: {
      type: String as PropType<string>,
      default: ''
    }
  })

  const ZGA = useZmartaAnalytics({
    country: ctx?.market,
    brand: ctx?.brand
  })

  const email = ref('')
  const emailSent = ref(false)
  const emailSending = ref(false)
  const inputTouched = ref(false)
  const inputFocused = ref(false)
  const inputValid = ref(false)
  const inputInvalid = ref(false)

  const valid = computed(() => {
    return inputTouched.value && !inputFocused.value && inputValid.value
  })

  const invalid = computed(() => {
    return inputTouched.value && inputInvalid.value
  })

  function onFocus () {
    inputFocused.value = true
  }

  function onInput () {
    const valid = validateEmail()

    inputInvalid.value = !valid
    inputValid.value = valid
  }

  function onBlur () {
    inputFocused.value = false
    inputTouched.value = true

    ZGA.event?.modal?.email({
      category: 'email-signup',
      label: 'email-field'
    })
  }

  async function sendLead () {
    if (emailSending.value) return
    emailSending.value = true

    ZGA.event.modal.click({
      category: 'email-signup',
      label: 'email-signup'
    })

    let valid = validateEmail()
    if (valid) {
      valid = await verifyEmailAddress()
    }

    if (!valid) {
      inputInvalid.value = true
      emailSending.value = false

      return
    } else {
      inputValid.value = true
    }

    await createCustomerLead()

    emailSent.value = true
    emailSending.value = false
  }

  function validateEmail () {
    const emailRegex = /^[a-zA-Z0-9\u007F-\uffff!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9\u007F-\uffff!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/
    const regex = new RegExp(emailRegex)

    return regex.test(email.value)
  }

  async function verifyEmailAddress () {
    const response = await request({
      method: 'POST',
      url: '/service/common-gateway/api/v1/customer/validate',
      headers: {
        brand: ctx?.brand,
        market: ctx?.market,
        country: ctx?.market
      },
      body: {
        email: email.value
      }
    })

    return !!(response?.email && response?.email === email.value)
  }

  async function createCustomerLead () {
    await request({
      method: 'POST',
      url: '/service/common-gateway/api/v1/customer/addLead',
      headers: {
        brand: ctx?.brand,
        market: ctx?.market,
        country: ctx?.market
      },
      body: {
        email: email.value,
        vertical: 'consumer-loan'
      }
    })
  }
</script>

<style scoped lang="scss">
  .container {
    container-type: inline-size;
  }

  .zg-lead {
    background-image: url('background-circles.svg');
    background-color: var(--brand-100);
    border-radius: radius(large);
    padding: rhythm();

    @container (min-width: 768px) {
      display: grid;
      grid-template: "illustration title title"
    "illustration text text"
    "input input button"
    "disclaimer disclaimer disclaimer";
      column-gap: rhythm("small");
    }

    @container (min-width: 992px) {
      grid-template: "illustration title input"
    "illustration text input"
    "illustration text button"
    "illustration text disclaimer";
    }

    &--with-background-element {
      background-repeat: no-repeat;
      background-size: rem(222px) rem(168px);
    }

    &--email-sent {
      @container (min-width: 768px) {
        grid-template: "illustration illustration title title"
    "illustration illustration text text"
    "icon title-completed title-completed title-completed"
    "icon text-completed text-completed text-completed";

        p.text {
          margin-bottom: rhythm("large");
        }
      }

      @container (min-width: 992px) {
        grid-template: "illustration title title"
    "illustration text icon"
    "illustration text title-completed"
    "illustration text text-completed";
      }
    }

    :deep(.zg-illustration) {
      grid-area: illustration;
      height: rem(162px);
      width: rem(162px);
    }
  }

  .title {
    @include type-title-m;

    grid-area: title;
  }

  .text {
    @include type-body-m;

    grid-area: text;
  }

  .input {
    grid-area: input;
  }

  #button {
    grid-area: button;

    &:focus {
      box-shadow: 0 0 0 4px var(--brand-300);
    }
  }

  .disclaimer {
    color: var(--gray-500);
    padding-top: rhythm(small);

    @include type-body-s;

    @include mq(large) {
      padding-top: rhythm(xsmall);
      max-width: rem(360px);
    }
  }

  .icon {
    grid-area: icon;

    @container (min-width: 992px) {
      justify-self: center;
    }
  }

  .title-completed {
    @include type-title-s;

    grid-area: title-completed;
  }

  .text-completed {
    @include type-body-s;

    grid-area: text-completed;
  }

  .sending {
    opacity: 0.5;
  }
</style>
